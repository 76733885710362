import './App.css';

import { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import ReactGA from 'react-ga';
import { Allotment } from 'allotment';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import usStates from './stub/us-states'

const TRACKING_ID = "UA-121336203-1"; // latlng.app
ReactGA.initialize(TRACKING_ID);

function App() {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [rowData, setRowData] = useState(); // Set rowData to Array of Objects, one Object per Row

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setRowData(usStates)
  }, [])  
 
  // Each Column Definition results in one Column.
  const [columnDefs] = useState([
    {field: 'label', filter: true, editable: true},
    {field: 'lat', editable: true},
    {field: 'lng', editable: true}
  ]);
 
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo( ()=> ({
    sortable: true
  }), []);
 
  // Example of consuming Grid Event
  const cellClickedListener = useCallback( event => {
    console.log('cellClicked', event);
  }, []);
 
  return (
    <div className="App">
      <Allotment>
        <div>          
          <div className="GridContainer ag-theme-alpine">
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API

              rowData={rowData} // Row Data for Rows

              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties

              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection='multiple' // Options - allows click selection of rows
              onCellClicked={cellClickedListener} // Optional - registering for Grid Event
            />
          </div>
        </div>
        <div className="App-header">          
          <div style={{padding: 32}}>
            <h3>Lat Lng App will be a place to easy plot your coordinates into a map.</h3>
            <p>Under #communityLedDevelopment</p>
          </div>
        </div>
      </Allotment>
      <div className="banner"><a href="https://community.latlng.app" target="_blank" rel="noopener noreferrer">Check out community.latlng.app</a></div>
    </div>
  );
}

export default App;
