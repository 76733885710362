const data = [
  {
    "label":"Alaska",
    "lat":61.3850,
    "lng":-152.2683
  },
  {
    "label":"Alabama",
    "lat":32.7990,
    "lng":-86.8073
  },
  {
    "label":"Arkansas",
    "lat":34.9513,
    "lng":-92.3809
  },
  {
    "label":"Arizona",
    "lat":33.7712,
    "lng":-111.3877
  },
  {
    "label":"California",
    "lat":36.1700,
    "lng":-119.7462
  },
  {
    "label":"Colorado",
    "lat":39.0646,
    "lng":-105.3272
  },
  {
    "label":"Connecticut",
    "lat":41.5834,
    "lng":-72.7622
  },
  {
    "label":"Delaware",
    "lat":39.3498,
    "lng":-75.5148
  },
  {
    "label":"Florida",
    "lat":27.8333,
    "lng":-81.7170
  },
  {
    "label":"Georgia",
    "lat":32.9866,
    "lng":-83.6487
  },
  {
    "label":"Hawaii",
    "lat":21.1098,
    "lng":-157.5311
  },
  {
    "label":"Iowa",
    "lat":42.0046,
    "lng":-93.2140
  },
  {
    "label":"Idaho",
    "lat":44.2394,
    "lng":-114.5103
  },
  {
    "label":"Illinois",
    "lat":40.3363,
    "lng":-89.0022
  },
  {
    "label":"Indiana",
    "lat":39.8647,
    "lng":-86.2604
  },
  {
    "label":"Kansas",
    "lat":38.5111,
    "lng":-96.8005
  },
  {
    "label":"Kentucky",
    "lat":37.6690,
    "lng":-84.6514
  },
  {
    "label":"Louisiana",
    "lat":31.1801,
    "lng":-91.8749
  },
  {
    "label":"Massachusetts",
    "lat":42.2373,
    "lng":-71.5314
  },
  {
    "label":"Maryland",
    "lat":39.0724,
    "lng":-76.7902
  },
  {
    "label":"Maine",
    "lat":44.6074,
    "lng":-69.3977
  },
  {
    "label":"Michigan",
    "lat":43.3504,
    "lng":-84.5603
  },
  {
    "label":"Minnesota",
    "lat":45.7326,
    "lng":-93.9196
  },
  {
    "label":"Missouri",
    "lat":38.4623,
    "lng":-92.3020
  },
  {
    "label":"Mississippi",
    "lat":32.7673,
    "lng":-89.6812
  },
  {
    "label":"Montana",
    "lat":46.9048,
    "lng":-110.3261
  },
  {
    "label":"North Carolina",
    "lat":35.6411,
    "lng":-79.8431
  },
  {
    "label":"North Dakota",
    "lat":47.5362,
    "lng":-99.7930
  },
  {
    "label":"Nebraska",
    "lat":41.1289,
    "lng":-98.2883
  },
  {
    "label":"New Hampshire",
    "lat":43.4108,
    "lng":-71.5653
  },
  {
    "label":"New Jersey",
    "lat":40.3140,
    "lng":-74.5089
  },
  {
    "label":"New Mexico",
    "lat":34.8375,
    "lng":-106.2371
  },
  {
    "label":"Nevada",
    "lat":38.4199,
    "lng":-117.1219
  },
  {
    "label":"New York",
    "lat":42.1497,
    "lng":-74.9384
  },
  {
    "label":"Ohio",
    "lat":40.3736,
    "lng":-82.7755
  },
  {
    "label":"Oklahoma",
    "lat":35.5376,
    "lng":-96.9247
  },
  {
    "label":"Oregon",
    "lat":44.5672,
    "lng":-122.1269
  },
  {
    "label":"Pennsylvania",
    "lat":40.5773,
    "lng":-77.2640
  },
  {
    "label":"Rhode Island",
    "lat":41.6772,
    "lng":-71.5101
  },
  {
    "label":"South Carolina",
    "lat":33.8191,
    "lng":-80.9066
  },
  {
    "label":"South Dakota",
    "lat":44.2853,
    "lng":-99.4632
  },
  {
    "label":"Tennessee",
    "lat":35.7449,
    "lng":-86.7489
  },
  {
    "label":"Texas",
    "lat":31.1060,
    "lng":-97.6475
  },
  {
    "label":"Utah",
    "lat":40.1135,
    "lng":-111.8535
  },
  {
    "label":"Virginia",
    "lat":37.7680,
    "lng":-78.2057
  },
  {
    "label":"Vermont",
    "lat":44.0407,
    "lng":-72.7093
  },
  {
    "label":"Washington",
    "lat":47.3917,
    "lng":-121.5708
  },
  {
    "label":"Wisconsin",
    "lat":44.2563,
    "lng":-89.6385
  },
  {
    "label":"West Virginia",
    "lat":38.4680,
    "lng":-80.9696
  },
  {
    "label":"Wyoming",
    "lat":42.7475,
    "lng":-107.2085
  }
]

export default data